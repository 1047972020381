import React, { useEffect, useState } from "react"

import { useCurrentUser } from "../../components/CurrentUserContext"
import { useTranslation } from "react-i18next"
import axios from "../../http/axios"

import Accordion from "react-bootstrap/Accordion"

import DefaultUserPic from "../../images/default_user.jpg"
import { DatePickerComponent } from "../../components/DatePickerComponent"
import moment from "moment"

const AdminDashboardQuestionsSection = props => {
  const { currentUser } = useCurrentUser()
  const { t, i18n } = useTranslation()
  const lang = i18n.language

  const [isSummerSchool, setSummerSchool] = useState(!!currentUser.schoolMode)
  const [meetingsArray, setMeetingsArray] = useState([])
  const [statisticsArray, setStatisticsArray] = useState([])
  const [dateRange, setDateRange] = useState([])
  const [startDate, endDate] = dateRange

  const monthNames = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]
  const monthNamesUa = [
    "Січня",
    "Лютого",
    "Березня",
    "Квітня",
    "Травня",
    "Червня",
    "Липня",
    "Серпня",
    "Вересня",
    "Жовтня",
    "Листопада",
    "Грудня",
  ]
  const monthNamesRu = [
    "Января",
    "Февраля",
    "Марта",
    "Апреля",
    "Мая",
    "Июня",
    "Июля",
    "Августа",
    "Сентября",
    "Октября",
    "Ноября",
    "Декабря",
  ]

  function changeMonth(item) {
    if (lang === "en") {
      return monthNames[new Date(item).getMonth()]
    }
    if (lang === "uk") {
      return monthNamesUa[new Date(item).getMonth()]
    }
    if (lang === "ru") {
      return monthNamesRu[new Date(item).getMonth()]
    }
  }

  const getEventMonth = item => {
    return changeMonth(item)
  }

  function startDateDatesWithoutMonth(item) {
    return new Date(item).getDate()
  }

  const changeDate = item => {
    props.changeDateDashboard(item)
    setDateRange(item)
  }

  const getYearFromADate = date => {
    return date.substr(0, date.indexOf("-"))
  }

  const changeDatePickerStart = data => {
    if (data) {
      let date = new Date(data),
        y = date.getFullYear(),
        m = date.getMonth()
      let firstDay = new Date(y, m, 1)
      return moment(firstDay).format("YYYY-MM-DD")
    } else {
      return ""
    }
  }

  const changeDatePickerEnd = data => {
    if (data) {
      let date = new Date(data),
        y = date.getFullYear(),
        m = date.getMonth()
      let lastDay = new Date(y, m + 1, 0)
      return moment(lastDay).format("YYYY-MM-DD")
    } else {
      return ""
    }
  }

  useEffect(async () => {
    setDateRange(props.dateRangeDashboard)
    if (currentUser && startDate && endDate) {
      await axios
        .get(
          `/${isSummerSchool ? "summer-schools" : "schools"}/${
            currentUser.schoolId
          }/messages` +
            `?lang=${lang}&fromDate=${changeDatePickerStart(
              startDate
            )}&toDate=${changeDatePickerEnd(endDate)}`
        )
        .then(res => {
          setMeetingsArray(res.data)
        })

      await axios
        .get(
          `/${isSummerSchool ? "summer-schools" : "schools"}/${
            currentUser.schoolId
          }/statistics` +
            `?lang=${lang}&fromDate=${changeDatePickerStart(
              startDate
            )}&toDate=${changeDatePickerEnd(endDate)}`
        )
        .then(res => {
          setStatisticsArray(res.data)
        })
    }
  }, [dateRange, currentUser])

  //-----ANSWER QUESTION

  const answerQuestion = question => {
    if (currentUser) {
      axios
        .put(
          `/${isSummerSchool ? "summer-schools" : "schools"}/${
            currentUser.schoolId
          }/messages/${question.id}/mark-as-answered`
        )
        .then(() => {
          const currentQuestions = [...meetingsArray]
          const prevQuestion = currentQuestions.find(x => x.id === question.id)
          prevQuestion.answered = true
          setMeetingsArray(currentQuestions)
        })
    }
  }

  return (
    <>
      <div className="main-dashboard-panel row d-flex justify-content-between">
        <div
          className={`dashboard-control-panel ${
            meetingsArray.length > 5 ? "scroll-enabled" : ""
          } f-js col-lg-6 mt-5`}
        >
          <div className="requests-list">
            {meetingsArray.map(item => {
              return (
                <div className="request">
                  <div key={item.id} className="f-jb">
                    <div className="request-photo-info f-js f-ac">
                      {item.accountImage ? (
                        <img
                          src={item.accountImage}
                          alt="no-user-picture"
                          className="request-picture"
                        />
                      ) : (
                        <img
                          src={DefaultUserPic}
                          alt="no-user-picture"
                          className="request-picture"
                        />
                      )}
                      <div className="request-user-info">
                        <div className="request-user-name">
                          {item.accountFullName}
                        </div>
                        <div className="request-user-details">
                          {item.content}
                        </div>
                      </div>
                    </div>
                    <div className="request-date-answer-wrapper">
                      <div className="request-date">
                        {startDateDatesWithoutMonth(item.createdDate) +
                          " " +
                          getEventMonth(item.createdDate) +
                          " " +
                          getYearFromADate(item.createdDate)}
                      </div>
                      {!item.answered && (
                        <div
                          className="request-answer-btn curs-P"
                          onClick={() => {
                            answerQuestion(item)
                          }}
                        >
                          {t("schoolAdmin.answer")}
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="user-details-acc">
                    <Accordion>
                      <Accordion.Item eventKey="0">
                        <Accordion.Header>
                          {t("schoolAdmin.userDetails")}
                        </Accordion.Header>
                        <Accordion.Body>
                          <div className="user-children-list-wrapper">
                            {item.children && item.children.length > 0 && (
                              <>
                                <div className="accordion-title">
                                  {t("schoolAdmin.children")}:
                                </div>
                                <div className="children-list">
                                  {item.children.map((child, index) => {
                                    return (
                                      <div
                                        key={child.fullname}
                                        className="request-user-details user-children-item"
                                      >
                                        <div>{index + 1})</div>
                                        <div>
                                          {t("schoolAdmin.childFullName")}
                                          :&nbsp;
                                          <span className="opacity-50">
                                            {child.fullname}
                                          </span>
                                        </div>
                                        <div>
                                          {t("schoolAdmin.admissionAge")}:&nbsp;
                                          <span className="opacity-50">
                                            {child.admissionAge}
                                          </span>
                                        </div>
                                        <div>
                                          {t("schoolAdmin.birthYear")}:&nbsp;
                                          <span className="opacity-50">
                                            {new Date(
                                              child.dateOfBirth
                                            ).getFullYear()}
                                          </span>
                                        </div>
                                      </div>
                                    )
                                  })}
                                </div>
                              </>
                            )}
                            {item.accountEmail && (
                              <div className="accordion-title">
                                {t("main.header.email")}:&nbsp;
                                <br />
                                <span className="request-user-details user-children-item">
                                  {item.accountEmail}
                                </span>
                              </div>
                            )}
                            {item.accountPhone && (
                              <div className="accordion-title">
                                {t("main.header.phone")}:&nbsp;
                                <br />
                                <span className="request-user-details user-children-item">
                                  {item.accountPhone}
                                </span>
                              </div>
                            )}
                          </div>
                        </Accordion.Body>
                      </Accordion.Item>
                    </Accordion>
                  </div>
                </div>
              )
            })}
          </div>
        </div>

        <div className="statistics-panel col-lg-5 mt-5">
          <a href="#" className="user-profile-info-link active">
            {t("schoolAdmin.stats")}
          </a>
          <DatePickerComponent
            dateRangeDashboard={props.dateRangeDashboard}
            changeDate={changeDate}
          />
          <div className="statistics-data">
            <div className="statistics-data-field f-jb f-ac">
              <div className="statistics-field-name">
                {t("schoolAdmin.visits")}
              </div>
              <div className="statistics-field-number">
                {statisticsArray.visits}
              </div>
            </div>
            <div className="statistics-data-field f-jb f-ac">
              <div className="statistics-field-name">
                {t("schoolAdmin.actTime")}
              </div>
              <div className="statistics-field-number">
                {statisticsArray.activeTime} {t("school.location.days")}
              </div>
            </div>
            <div className="statistics-data-field f-jb f-ac">
              <div className="statistics-field-name">
                {t("schoolAdmin.requests")}
              </div>
              <div className="statistics-field-number">
                {statisticsArray.requests}
              </div>
            </div>
            <div className="statistics-data-field f-jb f-ac">
              <div className="statistics-field-name">
                {t("schoolAdmin.questions")}
              </div>
              <div className="statistics-field-number">
                {statisticsArray.questions}
              </div>
            </div>
            <div className="statistics-data-field f-jb f-ac">
              <div className="statistics-field-name">
                {t("schoolAdmin.meetings")}
              </div>
              <div className="statistics-field-number">
                {statisticsArray.meetings}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminDashboardQuestionsSection
