import DatePicker from "react-datepicker"
import React, { useEffect, useState } from "react"


export const DatePickerComponent = (props) => {

  const [dateRange, setDateRange] = useState([new Date().setMonth(new Date().getMonth() - 1), new Date()])
  const [startDate, endDate] = dateRange

  useEffect(async () => {
    if (props.dateRangeDashboard) {
      setDateRange(props.dateRangeDashboard)
    }
  }, [])



  return (
    <div className="date-range-picker">
      <div className="date-range-picker">
        <DatePicker
          selectsRange={true}
          startDate={startDate}
          endDate={endDate}
          onChange={(update) => {
            props.changeDate(update)
            setDateRange(update)
          }}
          showMonthYearPicker
          dateFormat="MMMM, yyyy"
          className="w-100"
        />
      </div>
    </div>

  )
}
