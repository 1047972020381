import * as React from "react"

import AdminDashboardHeaderSection from "../../sections/admin_profile_sections/AdminDashboardHeaderSection"
import AdminDashboard from "../../sections/admin_profile_sections/AdminDashboard"

import { useCurrentUser } from "../../components/CurrentUserContext"

import AccessDeniedMessage from "../../components/AccessDeniedMessage"

import Layout from "../../components/Layout"

const AdminProfileDashboardRequests = () => {
  const { currentUser, userLoading } = useCurrentUser()

  return (
    <Layout isSecondLayout={true}>
      {currentUser && currentUser.accountType === "school" ? (
        <div className="global-wrapper user-saved">
          <AdminDashboardHeaderSection
            state1="active"
            choice_status1="chosen"
          />
          <AdminDashboard currentUser={currentUser} />
        </div>
      ) : (
        <AccessDeniedMessage
          currentUser={currentUser}
          userLoading={userLoading}
        />
      )}
    </Layout>
  )
}

export default AdminProfileDashboardRequests
