import React, { useEffect, useState } from "react"
import { Link } from "gatsby"

import { useTranslation } from "react-i18next"
import UserLoginName from "../UserLoginName"
import ChangeChoiceLanguage from "../../components/ChangeChoiceLanguage"

const AdminDashboardHeaderSection = props => {
  const { t, i18n } = useTranslation()
  const lang = i18n.language
  const [pathname, setPathname] = useState("")
  useEffect(() => {
    setPathname(window.location.pathname)
  }, [])

  return (
    <>
      <div className="header shrinked container px-5">
        <div className="row d-flex justify-content-between align-items-center">
          <div className="logo-menu-block f-ac f-js col">
            <Link to={`/${lang}`} className="">
              <div className="logo_full mR-20 shrink">
                <div className="logo_full_block logo_full_block_1">Smart</div>
                <div className="logo_full_block logo_full_block_2">
                  Admissions
                </div>
                <div className="logo_full_block logo_full_block_3">Service</div>
                <div className="logo_shrinked_block">
                  S&nbsp;&nbsp;A&nbsp;&nbsp;S
                </div>
              </div>
            </Link>

            <div className="user-profile-nav-bar f-ac gap-4">
              <div
                className={`user-nav-option-wrapper f-ac ${props.choice_status1}`}
              >
                <Link to={`/${lang}/admin-profile`}>
                  <div className={`user-nav-bar-option ${props.state1}`}>
                    {t("schoolAdmin.dashboard")}
                  </div>
                </Link>
              </div>
              <div
                className={`user-nav-option-wrapper f-ac ${props.choice_status2}`}
              >
                <Link to={`/${lang}/admin-profile/about`}>
                  <div className={`user-nav-bar-option ${props.state2}`}>
                    {t("schoolAdmin.schoolInfo")}
                  </div>
                </Link>
              </div>
              <div
                className={`user-nav-option-wrapper f-ac ${props.choice_status3}`}
              >
                <Link to={`/${lang}/admin-profile/settings`}>
                  <div className={`user-nav-bar-option ${props.state3}`}>
                    {t("school.header.settings")}
                  </div>
                </Link>
              </div>
            </div>
          </div>

          <div className="col-lg-4 login-panel f-ac f-je right-side-buttons py-4">
            <UserLoginName pathName={pathname} />
            <ChangeChoiceLanguage />
          </div>
        </div>
      </div>
    </>
  )
}

export default AdminDashboardHeaderSection
