import React, { useState, useEffect } from "react"

import Tabs from "../Tabs"

import AdminDashboardRequestsSection from "./AdminDashboardRequestsSection"
import AdminDashboardQuestionsSection from "./AdminDashboardQuestionsSection"
import AdminDashboardMeetingsSection from "./AdminDashboardMeetingsSection"

import { useTranslation } from "react-i18next"

import axios from "../../http/axios"
import { useCurrentUser } from "../../components/CurrentUserContext"

const AdminDashboard = props => {
  const { t } = useTranslation()
  const { currentUser } = useCurrentUser()

  const [isSummerSchool] = useState(!!currentUser.schoolMode)
  const [dateRangeDashboard, setDateRangeDashboard] = useState([
    new Date().setMonth(new Date().getMonth() - 1),
    new Date(),
  ])

  const changeDateDashboard = value => {
    setDateRangeDashboard(value)
  }

  const data = [
    {
      heading: (
        <div className=" f-ac f-js">
          <div className="f-ac chosen">
            <div className="user-profile-info-link active">
              {t("schoolAdmin.requests")}
            </div>
          </div>
        </div>
      ),
      body: (
        <AdminDashboardRequestsSection
          dateRangeDashboard={dateRangeDashboard}
          changeDateDashboard={changeDateDashboard}
        />
      ),
    },
    {
      heading: (
        <div className="f-ac f-js">
          <div className="f-ac chosen">
            <div className="user-profile-info-link active">
              {t("schoolAdmin.questions")}
            </div>
          </div>
        </div>
      ),
      body: (
        <AdminDashboardQuestionsSection
          dateRangeDashboard={dateRangeDashboard}
          changeDateDashboard={changeDateDashboard}
        />
      ),
    },
    {
      heading: (
        <div className=" f-ac f-js">
          <div className="f-ac chosen">
            <div className="user-profile-info-link active">
              {t("schoolAdmin.meetings")}
            </div>
          </div>
        </div>
      ),
      body: (
        <AdminDashboardMeetingsSection
          dateRangeDashboard={dateRangeDashboard}
          changeDateDashboard={changeDateDashboard}
        />
      ),
    },
  ]

  const [paymentStatus, setPaymentStatus] = useState({})

  const monthNames = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ]

  function getMonth(item) {
    return monthNames[new Date(item).getMonth()]
  }

  function formatDate(item) {
    return (
      getMonth(item) +
      " " +
      new Date(item).getDate() +
      ", " +
      new Date(item).getFullYear()
    )
  }

  useEffect(() => {
    axios
      .get(
        `/${isSummerSchool ? "summer-schools" : "schools"}/${
          props.currentUser.schoolId
        }/subscriptions`
      )
      .then(res => {
        setPaymentStatus(res.data)
      })
  }, [])

  return (
    <>
      <div className="f-jc">
        <div className="dashboard-content-wrapper container">
          <div className="row mb-5">
            <div className="user-profile-title f-js col-12">
              {t("schoolAdmin.dashboard")}
            </div>
          </div>
          <div className="row">
            <div className="col-12 payment-status-bar d-flex justify-content-between">
              {paymentStatus.status === 0 && (
                <div className="payment-status-text d-flex align-items-center gap-2">
                  <span className="paid-icon" />
                  <div>Paid</div>
                </div>
              )}
              {paymentStatus.status === 1 && (
                <div className="payment-status-text d-flex align-items-center gap-2">
                  <span className="payment-required" />
                  <div>Payment Required</div>
                </div>
              )}
              {paymentStatus.status === 2 && (
                <div className="payment-status-text d-flex align-items-center gap-2">
                  <span className="pending" />
                  <div>Pending</div>
                </div>
              )}

              {paymentStatus.nextPaymentDate && (
                <div className="next-payment-date">
                  Next payment will be{" "}
                  {formatDate(paymentStatus.nextPaymentDate)}
                </div>
              )}
            </div>
          </div>
          <Tabs data={data} />
        </div>
      </div>
    </>
  )
}

export default AdminDashboard
